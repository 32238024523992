import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { GithubLink, LinkedInLink, TwitterLink } from './Links';

const About: React.FC = () => {
  return (
    <article>
      <div
        css={css`
          display: grid;
          grid-template-areas:
            'photo headline'
            'links links'
            'bio bio';
          gap: 22px;
          grid-template-columns: 1fr 1fr;


          @media (max-width: 700px) {
            grid-template-areas:
              'photo'
              'headline'
              'links'
              'bio';
            justify-items: center;
            grid-template-rows: 150px repeat(3, auto);
            grid-template-columns: initial;
          }
        `}
      >
        <StaticImage
          placeholder="blurred"
          layout="constrained"
          src="../images/profile_photo.jpeg"
          alt="Zak Laughton"
          css={css`
            border-radius: 50%;
            grid-area: photo;
            width: 300px;
            margin: auto; 
            @media (max-width: 700px) {
              width: 150px;
            }
          `}
        />
        <div
          css={css`
            grid-area: headline;
            padding: var(--containerPadding);
          `}
        >
          <h2 css={css`
            @media (max-width: 700px) {
              margin-top: 0;
            }
          `}>Hi, I&apos;m Zak!</h2>
          <h2
            css={css`
              font-size: var(--h3);
            `}
          >
            I build scalable software to make life easier for users and other
            developers.
          </h2>
        </div>
        <div
          css={css`
            grid-area: links;
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            font-size: var(--h5);
          `}
        >
          <Link to="/blog">Blog</Link>
          <TwitterLink />
          <LinkedInLink />
          <GithubLink />
        </div>
        <div
          css={css`
            grid-area: bio;
          `}
        >
          <p>
            I&apos;m a full-stack developer with experience building with{' '}
            <strong>React.js</strong>, <strong>Node.js</strong>,{' '}
            <strong>GraphQL</strong>, <strong>Apollo</strong>, and{' '}
            <strong>Typescript</strong>. I&apos;m passionate about building
            scalable, maintainable components with modularized, tested code.
          </p>

          <p>
            Get to know more about my professional experience on{' '}
            <LinkedInLink />, or stay up-to-date with my latest ideas and
            learnings on <TwitterLink /> and my <Link to="/blog">blog</Link>.
          </p>
        </div>
      </div>
    </article>
  );
};

export default About;
